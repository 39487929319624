import { capitalize } from 'lodash';
import { Address } from './address';

export class CompanySearch {
    name: string;
    incorporation: string; // registration number
    address: Address;
    isDiffusible: boolean; // if company shares their info publicly on gov API or not

    constructor(obj: any) {
        this.name = capitalize(obj?.data?.attributes?.name);
        this.incorporation = capitalize(obj?.data?.attributes?.incorporation);
        if (obj?.data?.attributes?.address) { this.address = new Address(obj.data.attributes.address); }
        this.isDiffusible = obj?.data?.attributes?.is_diffusible;
    }
}
