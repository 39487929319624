import { Directive, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { unsubscribeOnDestroy } from 'src/app/shared/decorators/unsubscribe-on-destroy/unsubscribe-on-destroy.decorator';

@Directive({
    selector: '[appMaxLengthAllowed]'
})
export class MaxLengthAllowedDirective implements OnInit{
    @Input('appMaxLengthAllowed') maxLength: number | string;
    @Input() control: UntypedFormControl;

    ngOnInit(): void {
        if (this.control) {
            this._limitControlValue(); 
        } 
    }

    @unsubscribeOnDestroy
    private _limitControlValue(): Subscription {
        return this.control.valueChanges.subscribe((value: string) => {
            const maxLength = Number(this.maxLength);
            if (value.length > maxLength) {
                this.control.setValue(value.slice(0, maxLength));
            }
        });
    }
}
