import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services/language/language.service';

@Pipe({
    name: 'date'
})
export class LanguageDatePipe extends DatePipe implements PipeTransform {

    constructor(language: LanguageService) {
        super(language.getCurrentLang());
    }

}
