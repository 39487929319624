export type TransactionCategory = 'CREDIT_TRANSFER' | 'RECALL' | 'RETURN' | 'FEES' | 'DIRECT_DEBIT';

export type TransactionScheme = 'SEPA' | 'INSTANT_SEPA' | 'INTERNATIONAL' | 'SEPA_DIRECT_DEBIT';

export type TransactionCreditOrDebit = 'CREDIT' | 'DEBIT';

export class Transaction {
    id: string;
    amount: string;
    bookedDate: string;
    currency: string;
    category: TransactionCategory;
    scheme: TransactionScheme;
    credit_or_debit: TransactionCreditOrDebit;
    is_sepa: boolean;

    constructor(obj: any) {
        this.id = obj.id;
        this.amount = obj.amount;
        this.bookedDate = obj.bookedDate;
        this.currency = obj.currency;
        this.category = obj.category;
        this.scheme = obj.scheme;
        this.credit_or_debit = obj.credit_or_debit;
        this.is_sepa = obj.is_sepa;
    }

    isDebitTransaction(): boolean {
        return this.credit_or_debit === 'DEBIT';
    }

    isSwiftIct(): boolean {
        return this.scheme === 'INTERNATIONAL' && this.credit_or_debit === 'CREDIT' && !this.is_sepa;
    }
}
