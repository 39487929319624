import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ScreenSizeService } from '../../services/screen-size.service';

@Directive({
    selector: '[onScreenSize]'
})
export class OnScreenSizeDirective {

    @Input() set onScreenSize(screensList: string[]) {
        this.screenSize.isCurrentScreen(screensList).subscribe(
            isCurrentScreen => {
                if (isCurrentScreen) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private screenSize: ScreenSizeService,
    ) { }

}
