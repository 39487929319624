import { StatusType } from '@fintecture-npm/angular-ui';
import { Application } from './application';
import { EnvironmentType } from '../constants/environment-constants';

export class Shop extends Application {
    secret: string;
    url: string;
    platform: string;
    environment: EnvironmentType;

    constructor(obj: any) {
        super(obj);
        this.secret = obj.secret;
        this.url = obj.url;
        this.platform = obj.platform;
        this.environment = obj.environment;
    }

    getBadgeType(): StatusType {
        return this.environment?.toLocaleLowerCase() === 'production' ? 'success' : 'warning';
    }
}
