import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User, updatePassword, reauthenticateWithCredential, EmailAuthProvider, SAMLAuthProvider, unlink, linkWithPopup, UserCredential, OAuthProvider } from 'firebase/auth';
import { SessionService } from '../session/session.service';
import { ScreenSizeService } from '../screen-size.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private BIND_ID_PROVIDER = new OAuthProvider('oidc.bindid');

    constructor(
        private http: HttpClient,
        private fireAuth: AngularFireAuth,
        private session: SessionService,
        private screenSize: ScreenSizeService,
    ) { }

    getUser(): Observable<User> {
        return this.fireAuth.authState.pipe(first());
    }

    getUserTokenId(): Observable<string | null> {
        return this.getUser().pipe(
            switchMap(user => user ? user.getIdToken() : of(null))
        );
    }

    getUserId(): Observable<string | null> {
        return this.getUser().pipe(
            map(user => user?.uid)
        );
    }

    private setPersistence() {
        return from(this.fireAuth.setPersistence('session'));
    }

    register(email: string, password: string) {
        return this.setPersistence().pipe(
            switchMap(_ => this.fireAuth.createUserWithEmailAndPassword(email, password)),
            tap(_ => this.session.getSessionInfo()),
            tap(_ => this.session.shareSession())
        );
    }

    login(params: { email?: string, password?: string, domain?: string }): Observable<any> {
        if (this.session.isSessionActive()) {
            return throwError({ code: 'session-active' });
        } else {
            return this.setPersistence().pipe(
                switchMap(_ => {
                    if (params.domain) return this.ssoLogin(params.domain);
                    if (params.email && params.password) return this.firebaseLogin(params.email, params.password);

                    return throwError({ code: 'invalid-login-request' });
                }),
                switchMap(() => this.registerUserSession()),
                tap(_ => this.session.getSessionInfo()),
                tap(_ => this.session.shareSession())
            );
        }
    }

    private firebaseLogin(email: string, password: string) {
        return from(this.fireAuth.signInWithEmailAndPassword(email, password)).pipe(
            switchMap(() => this.http.post('/api/auth/login', {})),
            catchError(err => from(this.fireAuth.signOut()).pipe(
                switchMap(_ => {
                    if (err.error?.errors[0]?.code === 'MULTI_FACTOR_AUTH_REQUIRED') {
                        return this.bindIdLogin(email);
                    } else {
                        throwError(err);
                    }
                })
            ))
        );
    }

    bindIdLogin(email: string): Observable<any> {
        this.BIND_ID_PROVIDER.setCustomParameters({
            login_hint: `email:${email}`
        });
        return from(this.fireAuth.signInWithPopup(this.BIND_ID_PROVIDER)).pipe(
            switchMap(() => this.http.post('/api/auth/mfa-login', {})),
            catchError(err => from(this.fireAuth.signOut()).pipe(
                switchMap(_ => throwError(err))
            ))
        );
    }

    ssoLogin(domain: string) {
        return this.http.get(`/api/auth/sso/${domain}`).pipe(
            switchMap(
                (res: any) => {
                    const provider = new SAMLAuthProvider(res.provider_id);

                    return this.fireAuth.signInWithPopup(provider);
                }
            ),
            switchMap(() => this.http.post('/api/auth/sso-login', {})),
            catchError(err => from(this.fireAuth.signOut()).pipe(
                switchMap(_ => throwError(err))
            )),
            switchMap(() => this.deletePasswordProvider())
        );
    }

    bindIdLink(): Observable<UserCredential> {
        return this.getUser().pipe(
            switchMap((user) => {
                this.BIND_ID_PROVIDER.setCustomParameters({
                    login_hint: `email:${user.email}`
                });
                return from(linkWithPopup(user, this.BIND_ID_PROVIDER));
            })
        );
    }

    bindIdUnlink(): Observable<User> {
        return this.getUser().pipe(
            switchMap((user) => from(unlink(user, this.BIND_ID_PROVIDER.providerId)))
        );
    }

    logout(target: string = null) {
        return of(this.session.endSession(target));
    }

    sendPasswordResetEmail(email: string) {
        return this.http.get(`/api/auth/reset-password/${email}`);
    }

    confirmPasswordReset(code: string, password: string) {
        return from(this.fireAuth.confirmPasswordReset(code, password));
    }

    isEmailAvailable(email: string) {
        return from(
            this.fireAuth.fetchSignInMethodsForEmail(email)
        ).pipe(
            map(methods => methods.length === 0)
        );
    }

    isUserLoggedInChanges() {
        return this.fireAuth.authState.pipe(
            map(user => !!user)
        );
    }

    isUserLoggedIn() {
        return this.isUserLoggedInChanges().pipe(
            first()
        );
    }

    changeUserPassword(currentPassword: string, newPassword: string): Observable<void> {
        return this.getUser().pipe(
            switchMap(user => {
                const credential = EmailAuthProvider.credential(user.email, currentPassword);

                return from(reauthenticateWithCredential(user, credential)).pipe(
                    switchMap(() => updatePassword(user, newPassword))
                );
            })
        );
    }

    registerUserSession() {
        const deviceResolution = this.screenSize.getScreenResolution();

        return this.http.post('/api/auth/user-session', { deviceResolution });
    }

    private deletePasswordProvider(): Observable<any> {
        return this.getUser().pipe(
            switchMap(user => {
                const provider = user.providerData.find(provider => provider.providerId === 'password');
                return provider ? unlink(user, provider.providerId) : of(null);
            })
        );
    }
}
