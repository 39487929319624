import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequiredFunctionalityDirective } from './required-functionality.directive';

@NgModule({
    declarations: [RequiredFunctionalityDirective],
    imports: [
        CommonModule
    ],
    exports: [RequiredFunctionalityDirective]
})
export class RequiredFunctionalityModule { }
