import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AllowedRolesModule } from './directives/allowed-roles/allowed-roles.module';
import { LanguageDateModule } from './pipes/language-date/language-date.module';
import { OnScreenSizeModule } from './directives/on-screen-size/on-screen-size.module';
import { LanguageCurrencyModule } from './pipes/amount/language-currency.module';
import { RequiredFunctionalityModule } from './directives/required-functionality/required-functionality.module';
import { ActivationWarningModule } from './components/activation-warning/activation-warning.module';
import { CypressModule } from './directives/cypress/cypress.module';
import { MaxLengthAllowedModule } from './directives/max-length-allowed/max-length-allowed.module';
import { IbanModule } from './pipes/iban/iban.module';
import { ButtonModule, IconModule, ToastModule, TooltipModule } from '@fintecture-npm/angular-ui';
import { FraudShieldModule } from './components/fraud-shield-banner/fraud-shield-banner.module';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        TranslateModule,
        AllowedRolesModule,
        IconModule,
        ButtonModule,
        LanguageDateModule,
        OnScreenSizeModule,
        LanguageCurrencyModule,
        RequiredFunctionalityModule,
        ActivationWarningModule,
        CypressModule,
        IbanModule,
        MaxLengthAllowedModule,
        ToastModule,
        TooltipModule,
    ],
    exports: [
        CommonModule,
        TranslateModule,
        AllowedRolesModule,
        IconModule,
        ButtonModule,
        LanguageDateModule,
        OnScreenSizeModule,
        LanguageCurrencyModule,
        RequiredFunctionalityModule,
        ActivationWarningModule,
        CypressModule,
        MaxLengthAllowedModule,
        IbanModule,
        ToastModule,
        TooltipModule,
        FraudShieldModule
    ]
})
export class SharedModule { }
