import { Component } from '@angular/core';
import { LanguageService } from './shared/services/language/language.service';
import { onCookiesChange, whenOneTrustReady } from './shared/utils/one-trust';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {

    constructor(private language: LanguageService) {
        this.language.init();
        this.reloadOnCookiesChange();
    }

    reloadOnCookiesChange(): void {
        whenOneTrustReady(() => onCookiesChange(() => location.reload()));
    }
}
