import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';

declare type EnvironmentPreference = 'production' | 'sandbox';

@Injectable({
    providedIn: 'root'
})
export class SandboxService {

    public isSandboxSwitched: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.getEnvironment() === 'sandbox');
    public sandboxToggleControl = new UntypedFormControl(this.getEnvironment() === 'sandbox');
    
    constructor(private router: Router) {}

    isSandboxAvailable(): boolean {
        const allowedRoutes = ['/developers/event-simulator', '/bank-accounts', '/acquiring-account', '/payments'];
        return allowedRoutes.some(route => this.router.url.startsWith(route));
    }

    setEnvironment(toggled = this.sandboxToggleControl.value): void {
        const isSandboxSwitched = toggled;
        localStorage.setItem('prefs-environment', isSandboxSwitched ? 'sandbox' : 'production');
        this.isSandboxSwitched.next(isSandboxSwitched);
    }

    getEnvironment(): EnvironmentPreference {
        return localStorage.getItem('prefs-environment') as EnvironmentPreference || 'production';
    }

    handlerSandboxToggleControl(): Subscription {
        return this.sandboxToggleControl.valueChanges.subscribe(() => {
            this.setEnvironment();
        });
    }
}
