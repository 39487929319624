import { StatusType } from '@fintecture-npm/angular-ui';
import { Application } from './application';
import { uniq } from 'lodash';

export class DeveloperApplication extends Application {

    secret: string;
    description: string;
    color: string;
    urls: string[];
    webhooks: any[];
    scope: 'ais'|'pis';

    constructor(obj: any = {}) {
        super(obj);
        this.secret = obj.secret;
        this.description = obj.description;
        this.color = obj.color;
        this.urls = obj.urls;
        this.webhooks = obj.webhooks ? obj.webhooks : [];
        this.scope = obj.scope;
    }

    getWebhooksEvents(): string[] {
        const webhooks = this.webhooks.reduce((a,b) => a.concat(b.events), []);

        return uniq(webhooks);
    }

    getScopeBadgeType(): StatusType {
        return this.scope?.toLocaleLowerCase() === 'pis' ? 'info' : 'refund';
    }
}
