import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MembershipsService } from 'src/app/shared/services/memberships/memberships.service';

@Injectable({
    providedIn: 'root'
})
export class RolesService {

    constructor(private memberships: MembershipsService) { }

    getCurrentRole(): Observable<string> {
        return this.memberships.getCurrentMembership().pipe(
            map(membership => membership.role)
        );
    }

    userIs(roles: string|string[]): Observable<boolean> {
        return this.getCurrentRole().pipe(
            map(currentRole => roles.includes(currentRole))
        );
    }
}
