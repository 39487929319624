import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageDatePipe } from './language-date.pipe';



@NgModule({
    declarations: [LanguageDatePipe],
    imports: [
        CommonModule
    ],
    exports: [LanguageDatePipe]
})
export class LanguageDateModule { }
