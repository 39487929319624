import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CompaniesService } from 'src/app/shared/services/companies/companies.service';
import { PaymentMethod } from '../../models/payment-method';
import { DeveloperApplication } from '../../models/developer-application';
import { Shop } from '../../models/shop';
import { PointOfSale } from '../../models/point-of-sale';
import { PointsOfSaleService } from '../points-of-sale/points-of-sale.service';
import { ShopsService } from '../shops/shops.service';
import { DeveloperApplicationsService } from '../developer-applications/developer-applications.service';

export declare type ApplicationType = DeveloperApplication | Shop | PointOfSale;
@Injectable({
    providedIn: 'root'
})
export class ApplicationsService {

    constructor(
        private http: HttpClient,
        private companies: CompaniesService,
        private developers: DeveloperApplicationsService,
        private shops: ShopsService,
        private pointsOfSale: PointsOfSaleService
    ) {}

    getApplicationMethods(applicationId: string, environment: string): Observable<PaymentMethod[]> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap(companyId => <Observable<any>> this.http.get(`/api/companies/${companyId}/applications/${applicationId}`,
                { params: {'filter[environment]': environment } }
            )),
            map(applications => applications.data.relationships.payment_methods.data.map(s => new PaymentMethod(s)))
        );
    }

    public loadApplications(): Observable<ApplicationType[]> {
        return forkJoin([
            this.shops.getShops(),
            this.pointsOfSale.getPointsOfSale(),
            this.developers.getApplications()
        ]).pipe(
            map( ([pointsOfSale, shops, developers]) => [...pointsOfSale, ...shops, ...developers] ),
        );
    }

    public clearApplicationsCache(): void {
        this.shops.clearShopsCache();
        this.pointsOfSale.clearPointsOfSaleCache();
        this.developers.clearDevelopersCache();
    }
}
