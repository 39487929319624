import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IbanPipe } from './iban.pipe';


@NgModule({
    declarations: [IbanPipe],
    imports: [
        CommonModule
    ],
    exports: [IbanPipe],
    providers: [IbanPipe]
})
export class IbanModule { }
