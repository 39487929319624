import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { Membership } from '../../models/membership';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class MembershipsService {

    private memberships: Membership[];
    private currentMembership: Membership;
    private currentMembershipChanges: BehaviorSubject<Membership> = new BehaviorSubject<Membership>(null);

    constructor(
        private http: HttpClient,
        private auth: AuthService,
    ) {
        this.auth.isUserLoggedInChanges().subscribe(
            () => this.memberships = this.currentMembership = null
        );
    }

    getMemberships(): Observable<Membership[]> {
        if (this.memberships) { return of(this.memberships); }
        const params = { with_campaigns: true };

        return this.http.get('/api/memberships', { params }).pipe(
            map((memberships: any[]) => memberships.map(m => new Membership(m))),
            tap(memberships => this.memberships = memberships),
            catchError(err => {
                if (err.status === 404) {
                    this.auth.logout();
                }
                throw err;
            })
        );
    }

    getCurrentMembership(): Observable<Membership> {
        if (this.currentMembership) { return of(this.currentMembership); }

        return this.getMemberships().pipe(
            switchMap(memberships => {
                const selectedMembership = memberships.find(m => m.selected);
                if (!selectedMembership) { return this.updateCurrentMembership(memberships[0]); }

                this.currentMembership = selectedMembership;
                this.currentMembershipChanges.next(selectedMembership);

                return of(selectedMembership);
            }),
        );
    }

    getCurrentMembershipChanges(): Observable<Membership> {
        return this.currentMembershipChanges.asObservable().pipe(
            filter(m => m instanceof Membership)
        );
    }

    updateCurrentMembership(membership: Membership): Observable<Membership> {
        const params = { with_campaigns: true };

        return this.http.get(`/api/memberships/${membership.id}`, { params }).pipe(
            map(m => new Membership(m)),
            tap(membership => {
                const index = this.memberships.findIndex(m => m.id === membership.id);
                this.memberships[index] = membership;
                this.currentMembership = membership;
                this.currentMembershipChanges.next(membership);
            })
        );
    }

    reloadCurrentMembership(): Observable<Membership> {
        return this.getCurrentMembership().pipe(
            switchMap(membership => this.updateCurrentMembership(membership))
        );
    }

    updateLanguage(language: string): Observable<any> {
        const params = { lang: language };

        return this.getCurrentMembership().pipe(
            switchMap(
                membership => this.http.post(`/api/memberships/${membership.id}/config/language`, params).pipe(
                    switchMap(() => this.updateCurrentMembership(membership))
                )
            )
        );
    }
}
