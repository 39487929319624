import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllowedRolesDirective } from './allowed-roles.directive';



@NgModule({
    declarations: [AllowedRolesDirective],
    imports: [
        CommonModule
    ],
    exports: [AllowedRolesDirective]
})
export class AllowedRolesModule { }
