import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiInterceptor } from './shared/interceptors/api.interceptor';
import { registerLocaleData } from '@angular/common';
import { ASSETS_URL, ButtonModule, ToastModule } from '@fintecture-npm/angular-ui';

import en from '@angular/common/locales/en-GB';
import fr from '@angular/common/locales/fr';
import es from '@angular/common/locales/es';
import { SharedModule } from './shared/shared.module';
import { FeatureFlagsInterceptor } from './shared/interceptors/http.interceptor';

registerLocaleData(en, 'en');
registerLocaleData(fr, 'fr');
registerLocaleData(es, 'es');

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase_config),
        AngularFireAuthModule,
        ButtonModule, // Load here to include LoadingInterceptor
        ToastModule, // Load here to include ApiErrorsService
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        },
        {
            provide: ASSETS_URL,
            useValue: environment.assetsUrl
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: FeatureFlagsInterceptor,
            multi: true,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}