import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ScreenSizeConstants } from '../constants/screen-size-constants';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ScreenSizeService {

    private breakpoints = ScreenSizeConstants.breakpoints;
    private screenSizes = Object.keys(this.breakpoints);
    private currentScreenSizes: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

    constructor() {
        this.initResizeListener();
    }

    getCurrentScreenSizes(): Observable<string[]> {
        return this.currentScreenSizes.asObservable().pipe(
            distinctUntilChanged((prev, curr) => _.isEqual(prev, curr))
        );
    }

    getScreenResolution(): string {
        const width = window.screen.width;
        const height = window.screen.height;

        return `${width}x${height}`;
    }

    isCurrentScreen(screenSizeList: string|string[]): Observable<boolean> {
        return this.getCurrentScreenSizes().pipe(
            map(currentScreenSizes => currentScreenSizes.some(currentScreenSize => screenSizeList.includes(currentScreenSize))),
            distinctUntilChanged()
        );
    }

    private initResizeListener(): void {
        window.addEventListener('resize', (event: any) => this.parseScreenSizes(event.target.innerWidth));
        this.parseScreenSizes(window.innerWidth);
    }

    private parseScreenSizes(screenWidth: number): void {
        const screenSizes = this.screenSizes.filter(size => {
            const breakpoint = this.breakpoints[size];

            return (!breakpoint.min || breakpoint.min <= screenWidth) && (!breakpoint.max || breakpoint.max >= screenWidth);
        });
        this.currentScreenSizes.next(screenSizes);
    }

}
