import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export class State<T> {
    private currentState: BehaviorSubject<T>;

    constructor(initialState: T) {
        this.currentState = new BehaviorSubject(initialState);
    }

    /**
     * Get state synchronously
     * @returns T
     */
    get value(): T {
        return this.currentState.value;
    }

    get(): Observable<T> {
        return this.currentState.asObservable();
    }

    select(key: keyof T): Observable<any> {
        return this.get().pipe(
            map(state => state[key]),
            distinctUntilChanged()
        );
    }

    update(nextState: Partial<T>): void {
        this.currentState.next({...this.currentState.value, ...nextState});
    }

}
