import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { CompaniesService } from 'src/app/shared/services/companies/companies.service';
import { MembershipsService } from '../memberships/memberships.service';
import { PointOfSale } from '../../models/point-of-sale';

@Injectable({
    providedIn: 'root'
})
export class PointsOfSaleService {

    /**
  * This attribute will be used to store all the points of sale returned after the first call to the getPointsOfSale() function.
  * The rest of the functions will modify this attribute to simulate the CRUD changes.
  */
    private pointsOfSale: BehaviorSubject<PointOfSale[]>;

    constructor(private http: HttpClient, private companies: CompaniesService, private memberships: MembershipsService) {
        this.memberships.getCurrentMembershipChanges().subscribe(
            () => this.pointsOfSale = null
        );
    }

    clearPointsOfSaleCache(): void {
        this.pointsOfSale = null;
    }

    getPointsOfSale(): Observable<PointOfSale[]> {
        if (this.pointsOfSale) {
            return this.pointsOfSale.asObservable().pipe(filter(pointsOfSale => pointsOfSale !== null), first());
        } else {
            this.pointsOfSale = new BehaviorSubject<PointOfSale[]>(null);
            return this.companies.getCurrentCompanyId().pipe(
                switchMap(companyId => <Observable<any[]>>this.http.get(`/api/companies/${companyId}/applications/points-of-sale`, { params: { 'filter[environments]': ['production', 'sandbox'] } } )),
                map(pointsOfSale => <PointOfSale[]>pointsOfSale.map(s => new PointOfSale(s))),
                tap(pointsOfSale => this.pointsOfSale.next(pointsOfSale))
            );
        }
    }

    getFilteredPointsOfSale(environments: string[] = [], beneficiarytypes: string[] = []): Observable<PointOfSale[]> {
        return this.getPointsOfSale().pipe(
            map(pointsOfSale => environments.length > 0 ? pointsOfSale.filter(p => environments.includes(p.environment)) : pointsOfSale),
            map(pointsOfSale => beneficiarytypes.length > 0 ? pointsOfSale.filter(p => beneficiarytypes.includes(p.bankAccount?.type)) : pointsOfSale)
        );
    }

    getPointOfSale(id: string): Observable<PointOfSale> {
        return this.getPointsOfSale().pipe(
            map(pointsOfSale => pointsOfSale.find(s => s.id === id))
        );
    }

    createPointOfSale(pointOfSaleInfo: any): Observable<PointOfSale> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap(companyId => this.http.post(`/api/companies/${companyId}/applications/points-of-sale`, pointOfSaleInfo)),
            map(pointOfSale => new PointOfSale(pointOfSale)),
            tap(pointOfSale => this.pointsOfSale?.next(this.pointsOfSale && [...this.pointsOfSale.value, pointOfSale])),
        );
    }

    updatePointOfSale(id: string, pointOfSaleData: any): Observable<PointOfSale> {
        return forkJoin([
            this.companies.getCurrentCompanyId(),
            this.getPointOfSaleEnvironment(id).pipe( map(environment => ({ 'filter[environment]': environment })) )
        ]).pipe(
            switchMap(([companyId, params]) => this.http.put(`/api/companies/${companyId}/applications/points-of-sale/${id}`, pointOfSaleData, { params })),
            map(pointOfSale => new PointOfSale(pointOfSale)),
            tap(pointOfSale => this.updatePointOfSaleAttributes(id, pointOfSale))
        );
    }

    deletePointOfSale(id: string): Observable<void> {
        return forkJoin([
            this.companies.getCurrentCompanyId(),
            this.getPointOfSaleEnvironment(id).pipe( map(environment => ({ 'filter[environment]': environment })) )
        ]).pipe(
            switchMap(([companyId, params]) => <Observable<null>> this.http.delete(`/api/companies/${companyId}/applications/points-of-sale/${id}`, { params })),
            tap(() => this.pointsOfSale.next(this.pointsOfSale.value.filter(s => s.id !== id)))
        );
    }

    private getPointOfSaleEnvironment(id: string): Observable<string> {
        return this.getPointOfSale(id).pipe(
            map(pointOfSale => pointOfSale.environment)
        );
    }

    private updatePointOfSaleAttributes(id: string, attributes: PointOfSale): void {
        const target = this.pointsOfSale.value.find(s => s.id === id);
        Object.keys(attributes).forEach(k => {
            if (attributes[k] !== undefined) { target[k] = attributes[k]; }
        });
    }
}
