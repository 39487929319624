import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class MainGuard  {

    constructor(
        private auth: AuthService,
        private router: Router,
    ) { }

    canLoad(): Observable<boolean> {
        return this.checkUserLoggedIn();
    }

    canActivate(): Observable<boolean> {
        return this.checkUserLoggedIn();
    }

    private checkUserLoggedIn(): Observable<boolean> {
        return this.auth.isUserLoggedIn().pipe(
            map(isLoggedIn => {
                if (isLoggedIn) {
                    return true;
                }

                const queryParams: Params = {};

                if (window.location.pathname !== '/') {
                    queryParams.redirect = encodeURIComponent(window.location.pathname + window.location.search);
                }

                this.router.navigate(['/auth/login'], {
                    queryParams,
                    queryParamsHandling: 'merge',
                });

                return false;
            })
        );
    }

}
