export class PaymentStatus {
    static readonly SESSION_STATUS = {
        SESSION_INITATED: 'session_initiated',
        PROVIDER_REQUIRED: 'provider_required',
        SCA_REQUIRED: 'sca_required',
        CONFIRMATION_REQUIRED: 'confirmation_required',
        PAYMENT_CREATED: 'payment_created',
        PAYMENT_UNSUCCESSFUL: 'payment_unsuccessful',
        PAYMENT_PENDING: 'payment_pending',
        PAYMENT_ERROR: 'payment_error',
        PAYMENT_UNKNOWN: 'payment_unknown',
        PAYMENT_CANCELLED: 'payment_cancelled',
        PAYMENT_WAITING: 'payment_waiting',
        IBAN_REQUIRED: 'iban_required',
        IBAN_RECEIVED: 'iban_received',
        PAYMENT_EXPIRED: 'payment_expired',
        ORDER_CREATED: 'order_created',
        PENDING_VALIDATION: 'pending_validation',
        PAYMENT_PARTIAL: 'payment_partial',
        PAYMENT_COVERED: 'payment_covered',
        ENABLED: 'enabled',
        DISABLED: 'disabled'
    };

    static readonly BNPL_STATUS = {
        PAYMENT_PLANNED: 'payment_planned'
    };
}

export const FRAUDULENT_CASES = {
    FRAUD_SUSPECTED: 'fraud_suspected', // regular cases
    FRAUD_SUSPECTED_AUTOREFUND: 'fraud_suspected_autorefunded', // when an auto refund is requested - i.e., for the original PPS
    FRAUD_REFUND: 'fraud_refund', // set for the actual fraud refund PPS
    FRAUD_SUSPECTED_NO_OVERRIDE: 'fraud_suspected_no_override', // fraud detected but we were not able to block the transaction
};
