import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { MembershipsService } from '../memberships/memberships.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    private langs: string[] = ['en', 'fr', 'es'];
    private defaultLang = 'en';
    private localStorageKey = 'userLang';

    constructor(
    private translate: TranslateService,
    private memberships: MembershipsService,
    private auth: AuthService,
    ) { }

    init() {
        this.translate.addLangs(this.langs);
        this.translate.setDefaultLang(this.defaultLang);
        this.auth.isUserLoggedIn().subscribe(
            loggedIn => loggedIn ? null : this.setUserLanguage(this.getLangFromClient())
        );
        this.getUserLang().subscribe(
            lang => this.setUserLanguage(lang)
        );
    }

    getLangs() {
        return this.langs;
    }

    getCurrentLang() {
        return this.translate.currentLang;
    }

    getUserLang() {
        return this.memberships.getCurrentMembershipChanges().pipe(
            map(membership => membership?.language || this.getLangFromClient())
        );
    }

    getLangFromClient() {
        const lang = localStorage.getItem(this.localStorageKey) || this.translate.getBrowserLang();

        return this.langs.includes(lang) ? lang : this.defaultLang;
    }

    setUserLanguage(lang: string) {
        lang = this.langs.includes(lang) ? lang : this.defaultLang;
        if (this.langs.includes(lang)) {
            this.translate.use(lang);
            document.documentElement.lang = lang;
            localStorage.setItem(this.localStorageKey, lang);
        }
    }
}
