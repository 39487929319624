import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { filter, map, switchMap, tap, first, catchError } from 'rxjs/operators';
import { CompaniesService } from 'src/app/shared/services/companies/companies.service';
import { MembershipsService } from '../memberships/memberships.service';
import { Shop } from '../../models/shop';

@Injectable({
    providedIn: 'root'
})
export class ShopsService {

    /**
  * This attribute will be used to store all the shops returned after the first call to the getShops() function.
  * The rest of the functions will modify this attribute to simulate the CRUD changes.
  */
    private shops: BehaviorSubject<Shop[]>;
    private cms: BehaviorSubject<string[]>;

    constructor(private http: HttpClient, private companies: CompaniesService, private memberships: MembershipsService) {
        this.memberships.getCurrentMembershipChanges().subscribe(
            () => this.shops = null
        );
    }

    clearShopsCache(): void {
        this.shops = null;
    }

    getShops(): Observable<Shop[]> {
        if (this.shops) {
            return this.shops.asObservable().pipe(filter(shops => shops !== null), first());
        } else {
            this.shops = new BehaviorSubject<Shop[]>(null);
            return this.companies.getCurrentCompanyId().pipe(
                switchMap(companyId => <Observable<any[]>>this.http.get(`/api/companies/${companyId}/applications/shops`, { params: { 'filter[environments]': ['production', 'sandbox'] } } )),
                map(shops => <Shop[]>shops.map(s => new Shop(s))),
                tap(shops => this.shops.next(shops)),
            );
        }
    }

    getFilteredShops(environments: string[] = [], beneficiarytypes: string[] = []): Observable<Shop[]> {
        return this.getShops().pipe(
            map(shops => environments.length > 0 ? shops.filter(s => environments.includes(s.environment)) : shops),
            map(shops => beneficiarytypes.length > 0 ? shops.filter(s => beneficiarytypes.includes(s.bankAccount?.type)) : shops)
        );
    }

    getShop(id: string): Observable<Shop> {
        return this.getShops().pipe(
            map(shops => shops.find(s => s.id === id))
        );
    }

    createShop(shopInfo: any): Observable<Shop> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap(companyId => this.http.post(`/api/companies/${companyId}/applications/shops`, shopInfo)),
            map(shop => new Shop(shop)),
            tap(shop => this.shops?.next(this.shops && [...this.shops.value, shop])),
            catchError((err) => {
                throw err;
            }),
        );
    }

    updateShop(id: string, shopData: any): Observable<Shop> {
        return forkJoin([
            this.companies.getCurrentCompanyId(),
            this.getShopEnvironment(id).pipe(map(environment => ({ 'filter[environment]': environment }))),
        ]).pipe(
            switchMap(([companyId, params]) => this.http.put(`/api/companies/${companyId}/applications/shops/${id}`, shopData, { params })),
            map(shop => new Shop(shop)),
            tap(shop => this.updateShopAttributes(id, shop)),
            catchError((err) => {
                throw err;
            }),
        );
    }

    deleteShop(id: string): Observable<void> {
        return forkJoin([
            this.companies.getCurrentCompanyId(),
            this.getShopEnvironment(id).pipe(map(environment => ({ 'filter[environment]': environment }))),
        ]).pipe(
            switchMap(([companyId, params]) => <Observable<null>> this.http.delete(`/api/companies/${companyId}/applications/shops/${id}`, { params })),
            tap(() => this.shops.next(this.shops.value.filter(s => s.id !== id)))
        );
    }

    private getShopEnvironment(id: string): Observable<string> {
        return this.getShop(id).pipe(
            map(shop => shop.environment)
        );
    }

    private updateShopAttributes(id: string, attributes: Shop): void {
        const target = this.shops.value.find(s => s.id === id);
        Object.keys(attributes).forEach(k => {
            if (attributes[k] !== undefined) { target[k] = attributes[k]; }
        });
    }

    public getCMS(): Observable<string[]> {
        if (!this.cms) {
            this.cms = new BehaviorSubject<string[]>(null);
            this.http.get('/api/cms').subscribe((cms: string[]) => this.cms.next(cms));
        }
        
        return this.cms.asObservable().pipe(
            filter(cms => cms !== null),
            first()
        );
    }
}
