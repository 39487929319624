import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageCurrencyPipe } from './language-currency.pipe';


@NgModule({
    declarations: [LanguageCurrencyPipe],
    imports: [
        CommonModule
    ],
    exports: [LanguageCurrencyPipe],
    providers: [LanguageCurrencyPipe]
})
export class LanguageCurrencyModule { }
