import { Component, Input, OnInit } from '@angular/core';
import { FRAUDULENT_CASES } from '../../constants/payment-status';
import { PaymentsService } from 'src/app/modules/main/modules/payments/services/payments.service';
import { Payment } from '../../models/payment';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-fraud-shield-banner',
    templateUrl: './fraud-shield-banner.component.html',
    styleUrls: ['./fraud-shield-banner.component.scss']
})
export class FraudShieldBannerComponent implements OnInit {
    @Input() payment?: Payment;

    isPremiumFeature$: Observable<boolean>;

    constructor(private paymentsService: PaymentsService){}

    ngOnInit(): void {
        this._initPremiumFeature();
    }

    get text(): string {
        switch(this.payment?.fraudDetails) {
            case FRAUDULENT_CASES.FRAUD_SUSPECTED:
                return 'main.payments.fraud-shield.fraud-suspected';    
            case FRAUDULENT_CASES.FRAUD_SUSPECTED_AUTOREFUND:
                return 'main.payments.fraud-shield.fraud-suspected-autorefund';    
            case FRAUDULENT_CASES.FRAUD_REFUND:
                return 'main.payments.fraud-shield.fraud-refund';    
            case FRAUDULENT_CASES.FRAUD_SUSPECTED_NO_OVERRIDE:
                return 'main.payments.fraud-shield.fraud-suspected-no-override';
            default:
                return this.isFraudulentTranferReason ? 'main.payments.fraud-shield.fraudulent-originated' : 'main.applications.premium-feature-tag';
        }
    }

    // Payment status not bring this fraudulent case (the bank flagged a payment as fraud and blocked it) => we need to check the transfer reason
    get isFraudulentTranferReason(): boolean {
        return this.payment?.transferReason === 'fraudulent_originated';
    }

    get isApplicationPage(): boolean {
        return !this.payment;
    }

    // If linked to PFS => Premium feature tag.
    _initPremiumFeature(): void {
        this.isPremiumFeature$ = !this.isApplicationPage 
            ? this.paymentsService.isFraudulentPremium(this.payment) 
            : of (true);
    }
}
