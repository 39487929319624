import { EnvironmentType } from '../constants/environment-constants';
import { BENEFICIARY_TYPES } from 'src/app/shared/constants/beneficiary-types-constants';

export declare type BankAccountType = 'internal' | 'internal_acquiring' | 'external' | 'payout';
export class BankAccount {
    id: string;
    environment: EnvironmentType;
    holderName: string;
    commercialHolderName: string | null;
    iban: string;
    swiftBic: string;
    hasInstantSepa: boolean;
    currency: string;
    status: string;
    type: BankAccountType;
    email: string;
    acquiringAccountId: string;

    constructor(obj: any = {}) {
        this.id = obj.id;
        this.environment = obj.environment;
        this.holderName = this._getHolderName(obj.type, obj.holderName);
        this.commercialHolderName = obj.commercialHolderName;
        this.iban = obj.iban;
        this.swiftBic = obj.swiftBic;
        this.hasInstantSepa = obj.hasInstantSepa;
        this.currency = obj.currency;
        this.status = obj.status;
        this.type = obj.type;
        this.email = obj.email;
        this.acquiringAccountId = obj.acquiringAccountId;
    }

    private _getHolderName(beneficiaryType: string, holderName): string {
        if (beneficiaryType === BENEFICIARY_TYPES.PAYMENT_ACCOUNT) {
            return 'Payment account';
        }
        if (beneficiaryType === BENEFICIARY_TYPES.PAYMENT_ACQUIRING) {
            return 'Fintecture account';
        }

        return holderName;
    }
}
