import { Injectable, computed, signal } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagsService {
    private $activeFlags = signal<Set<string>>(new Set());

    updateActiveFlags(flags: string[]) {
        this.$activeFlags.set(new Set(flags));
    }
    
    $isFeatureActive(feature: string) {
        return computed(() => this.$activeFlags().has(feature));
    }
}
