import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { CompaniesService } from 'src/app/shared/services/companies/companies.service';
import { DeveloperApplication } from '../../models/developer-application';
import { MembershipsService } from '../memberships/memberships.service';

@Injectable({
    providedIn: 'root'
})
export class DeveloperApplicationsService {

    /**
  * This attribute will be used to store all the applications returned after the first call to the getApplications() function.
  * The rest of the functions will modify this attribute to simulate the CRUD changes.
  */
    private applications: BehaviorSubject<DeveloperApplication[]>;

    constructor(private http: HttpClient, private companies: CompaniesService, private memberships: MembershipsService) {
        this.memberships.getCurrentMembershipChanges().subscribe(
            () => this.applications = null
        );
    }

    clearDevelopersCache(): void {
        this.applications = null;
    }

    getApplications(): Observable<DeveloperApplication[]> {
        if (this.applications) {
            return this.applications.asObservable().pipe(filter(applications => applications !== null), first());
        } else {
            this.applications = new BehaviorSubject<DeveloperApplication[]>(null);
            return this.companies.getCurrentCompanyId().pipe(
                switchMap(companyId => <Observable<any[]>>this.http.get(`/api/companies/${companyId}/applications/developers`, { params: { 'filter[environments]': ['production', 'sandbox'] } })),
                map(applications => applications.map(s => new DeveloperApplication(s))),
                tap(applications => this.applications.next(applications))
            );
        }
    }

    getPisApplications(): Observable<DeveloperApplication[]> {
        return this.getApplications().pipe(
            map(apps => apps.filter(app => app.scope === 'pis'))
        );
    }

    getFilteredPisApplications(environments: string[] = [], beneficiarytypes: string[] = []): Observable<DeveloperApplication[]> {
        return this.getPisApplications().pipe(
            map(applications => environments.length > 0 ? applications.filter(a => environments.includes(a.environment)) : applications),
            map(applications => beneficiarytypes.length > 0 ? applications.filter(a => beneficiarytypes.includes(a.bankAccount?.type)) : applications)
        );
    }

    getApplication(id: string): Observable<DeveloperApplication> {
        return this.getApplications().pipe(
            map(applications => applications.find(s => s.id === id))
        );
    }

    createApplication(applicationInfo: any): Observable<DeveloperApplication> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap(companyId => this.http.post(`/api/companies/${companyId}/applications/developers`, applicationInfo)),
            map(application => new DeveloperApplication(application)),
            tap(application => this.applications?.next(this.applications && [...this.applications.value, application])),
        );
    }

    updateApplication(id: string, applicationData: any): Observable<DeveloperApplication> {
        return forkJoin([
            this.companies.getCurrentCompanyId(),
            this.getApplicationEnvironment(id).pipe( map(environment => ({ 'filter[environment]': environment })) )
        ]).pipe(
            switchMap(([companyId, params]) => this.http.put(`/api/companies/${companyId}/applications/developers/${id}`, applicationData, { params })),
            map(application => new DeveloperApplication(application)),
            tap(application => this.updateApplicationAttributes(id, application))
        );
    }

    deleteApplication(id: string): Observable<void> {
        return forkJoin([
            this.companies.getCurrentCompanyId(),
            this.getApplicationEnvironment(id).pipe( map(environment => ({ 'filter[environment]': environment })) )
        ]).pipe(
            switchMap(([companyId, params]) => <Observable<null>> this.http.delete(`/api/companies/${companyId}/applications/developers/${id}`, { params })),
            tap(() => this.applications.next(this.applications.value.filter(s => s.id !== id)))
        );
    }

    sanitizeHeadersWebhooks(webhooks): object[] {
        return webhooks.map(webhook => {
            webhook.headers = webhook.headers.filter(header => header.name && header.value);
            return webhook;
        });   
    }

    private getApplicationEnvironment(id: string): Observable<string> {
        return this.getApplication(id).pipe(
            map(application => application.environment)
        );
    }

    private updateApplicationAttributes(id: string, attributes: DeveloperApplication): void {
        const target = this.applications.value.find(s => s.id === id);
        Object.keys(attributes).forEach(k => {
            if (attributes[k] !== undefined) { target[k] = attributes[k]; }
        });
    }
}
