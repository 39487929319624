import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompaniesService } from 'src/app/shared/services/companies/companies.service';

@Injectable({
    providedIn: 'root'
})
export class FunctionalitiesService {

    constructor(private companies: CompaniesService) { }

    hasFunctionality(requiredFunctionality: string): Observable<boolean> {
        return this.companies.getCurrentCompany().pipe(
            map(company => company.functionalities.includes(requiredFunctionality))
        );
    }
}
