import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaxLengthAllowedDirective } from './max-length-allowed.directive';

@NgModule({
    declarations: [MaxLengthAllowedDirective],
    imports: [
        CommonModule
    ],
    exports: [MaxLengthAllowedDirective]
})
export class MaxLengthAllowedModule { }
