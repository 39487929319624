export class PaymentSection {
    section_name: string;
    amount: number;
    count: number;

    constructor(obj: any) {
        this.section_name = obj.section_name;
        this.amount = obj.amount;
        this.count = obj.count;
    }
}
