import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CompaniesService } from '../../services/companies/companies.service';

@Component({
    selector: 'app-activation-warning',
    templateUrl: './activation-warning.component.html',
    styleUrls: ['./activation-warning.component.scss']
})
export class ActivationWarningComponent implements OnInit {
    @Input() message = 'main.create-payment.activate-your-account';

    accountActivated$: Observable<boolean>;

    constructor(private companies: CompaniesService) { }

    ngOnInit(): void {
        this.loadActivationStatus();
    }

    loadActivationStatus(): void {
        this.accountActivated$ = this.companies.isCurrentCompanyActivated();
    }
}
