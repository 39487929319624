import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewCompanyFields } from '../../interfaces/new-company-fields.interface';
import { Company } from '../../models/company';
import { MembershipsService } from '../memberships/memberships.service';
import { CompanySearch } from '../../models/company-search';

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {
    private searches: { [key: string]: Observable<CompanySearch> } = {};

    constructor(
        private http: HttpClient, private memberships: MembershipsService
    ) { }

    createCompany(company: NewCompanyFields): Observable<any> {
        return this.http.post('/api/companies', company);
    }

    getCurrentCompany(): Observable<Company> {
        return this.memberships.getCurrentMembership().pipe(
            map(membership => membership.company)
        );
    }

    getCurrentCompanyId(): Observable<string> {
        return this.getCurrentCompany().pipe(
            map(company => company.id)
        );
    }

    isCurrentCompanyActivated(): Observable<boolean> {
        return this.getCurrentCompany().pipe(
            map(company => company.isActivated())
        );
    }

    isCurrentCompanyChurned(): Observable<boolean> {
        return this.getCurrentCompany().pipe(
            map(company => company.isChurned())
        );
    }

    isCurrentCompanyActivationIncomplete(): Observable<boolean> {
        return this.getCurrentCompany().pipe(
            map(company => company.isActivationIncomplete())
        );
    }

    isCurrentCompanyActivationBlocked(): Observable<boolean> {
        return this.getCurrentCompany().pipe(
            map(company => company.isActivationBlocked())
        );
    }

    hasDelayedPaymentFunctionality(): Observable<boolean> {
        return this.getCurrentCompany().pipe(
            map(company => company.functionalities.includes('delayed_payment'))
        );
    }

    hasCampaign(campaignName: string): Observable<boolean> {
        return this.getCurrentCompany().pipe(
            map(company => company.campaigns.some((campaign) => campaign.utmCampaign === campaignName))
        );
    }

    searchCompany(
        registrationNumber: string
    ): Observable<CompanySearch> {
        if (!registrationNumber) {
            return EMPTY;
        }
        const params = { registration_number: registrationNumber };

        if (!this.searches[registrationNumber]) {
            this.searches[registrationNumber] = this.http.get('/api/search/companies', { params }).pipe(
                map((result) =>  new CompanySearch(result)),
                shareReplay(),
            );
        }

        return this.searches[registrationNumber];
    }
}
