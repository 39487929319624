import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RolesService } from '../../services/roles/roles.service';

@Directive({
    selector: '[allowedRoles]'
})
export class AllowedRolesDirective {

  @Input() set allowedRoles(rolesList: string[]) {
        if (!rolesList) {
            this.viewContainer.createEmbeddedView(this.templateRef);

            return;
        }

        this.roles.userIs(rolesList).subscribe(
            isUserAllowed => {
                if (isUserAllowed) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            }
        );
    }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private roles: RolesService
  ) { }

}
