import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainGuard } from './guards/main.guard';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'redirect',
        loadChildren: () => import('./modules/redirect/redirect.module').then(m => m.RedirectModule)
    },
    {
        path: '',
        canLoad: [MainGuard],
        canActivate: [MainGuard],
        loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule)
    },
    { path: '**', pathMatch: 'full', redirectTo: '/auth/login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'disabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
