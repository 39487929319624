export const environment = {
    production: false,
    env_name: 'test',
    apiUrl: 'https://console-api-test.fintecture.com',
    assetsUrl: 'https://assets-test.fintecture.com',
    firebase_config: {
        apiKey: 'AIzaSyCWIBwssLv1RdkPdPhYUmFlNyBkrWfDakE',
        authDomain: 'fintecture-test.firebaseapp.com',
        databaseURL: 'https://fintecture-test.firebaseio.com',
        projectId: 'fintecture-test',
        storageBucket: 'fintecture-test.appspot.com',
        messagingSenderId: '47796211245'
    },
    enableIntercomChat: true,
    intercomId: 'bcejeh1x',
    mandatoryLoginDomain: [],
    dd: {
        site: 'datadoghq.eu',
        token: 'pub90905692390739a2cc63c479429035fc',
    },
};
