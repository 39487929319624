import { NgModule } from '@angular/core';
import { ActivationWarningComponent } from './activation-warning.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BadgeModule, NotificationModule } from '@fintecture-npm/angular-ui';

@NgModule({
    declarations: [ActivationWarningComponent],
    imports: [
        TranslateModule,
        CommonModule,
        RouterModule,
        BadgeModule,
        NotificationModule
    ],
    exports: [ActivationWarningComponent]
})
export class ActivationWarningModule { }
