<fin-notification type="info">
    <fin-icon class="security" name="security" size="20" inline></fin-icon>
    <div class="content" [ngClass]="{'premium': (isPremiumFeature$ | async) === true }">
        <div class="header">
            <span class="fin-info title"> {{ 'main.applications.fraud-shield' | translate }}</span>
            <fin-badge *ngIf="isPremiumFeature$ | async" layout="chip" [text]="'main.applications.premium-feature' | translate"></fin-badge>
        </div>
        <div class="fin-info text">
            {{ text | translate }}
            <span *ngIf="isApplicationPage" [innerHTML]="'main.applications.premium-feature-faq' | translate"></span>
        </div>
    </div>
</fin-notification>
