import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FunctionalitiesService } from '../../services/functionalities/functionalities.service';

@Directive({
    selector: '[requiredFunctionality]'
})
export class RequiredFunctionalityDirective {

    @Input() set requiredFunctionality(functionality: string) {
        if (!functionality) {
            this.viewContainer.createEmbeddedView(this.templateRef);

            return;
        }

        this.functionalities.hasFunctionality(functionality).subscribe(
            hasFunctionality => {
                if (hasFunctionality) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            }
        );
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private functionalities: FunctionalitiesService
    ) { }

}
