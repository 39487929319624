import { Company } from './company';

export class Membership {
    id: string;
    company: Company;
    role: string;
    language: string;
    selected?: boolean;
    applications?: {
        id: string;
        name: string;
        enabled: boolean;
    }[];

    constructor(obj: any) {
        this.id = obj.id;
        this.company = obj.company && new Company(obj.company);
        this.role = obj.role;
        this.language = obj.config?.language;
        this.selected = obj.selected;
        if (obj.applications) this.applications = obj.applications;
    }

    getInitialUrl(): string {
        switch (this.role) {
            case 'sales':
                return '/create-payment';

            case 'accountant':
                return '/payments';

            default:
                return '/';
        }
    }
}
