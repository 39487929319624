import { datadogLogs } from '@datadog/browser-logs';
import { areCookiesAccepted, deleteCookie, whenOneTrustReady } from './app/shared/utils/one-trust';
import { environment } from './environments/environment';

const DATADOG_COOKIES = '_dd_s';

export function initializeDatadog(): void {
    whenOneTrustReady(() => {
        if (!areCookiesAccepted('performance')) {
            deleteCookie(DATADOG_COOKIES);
            return;
        }
    
        datadogLogs.init({
            clientToken: environment.dd.token,
            site: environment.dd.site,
            env: environment.env_name,
            forwardErrorsToLogs: true,
            sampleRate: 100,
            service: 'console-2-frontend',
        });
    });
}
