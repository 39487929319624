import { PaymentMethodBankAccountType, PaymentMethodId, PaymentMethodStatus } from '../constants/payment-methods-constants';
import { PaymentMethodPlacement } from './payment-method-placement';

export class PaymentMethod {
    id: PaymentMethodId;
    applicationId: string;
    status: PaymentMethodStatus;
    order: number;
    bankAccountType: PaymentMethodBankAccountType;
    placements: PaymentMethodPlacement[];

    constructor(obj: any = {}) {
        this.id = obj.id || obj.method_id;
        this.status = obj.attributes?.status || obj.status;
        this.order = obj.attributes?.order || obj.order;
        this.applicationId = obj.attributes?.application_id || obj.application_id;
        this.bankAccountType = obj.attributes?.bank_account_type || obj.bank_account_type;
        this.placements = obj.attributes?.placements || obj.placements;
    }
}
