import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    
    private activeRequests = 0;
    private loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    
    getLoading$(): Observable<boolean> {
        return this.loading$.asObservable();
    }

    addRequest() {
        if (this.activeRequests === 0) {
            this.loading$.next(true);
        }

        this.activeRequests++;
    }
    
    removeRequest() {
        this.activeRequests--;

        if (this.activeRequests === 0) {
            this.loading$.next(false);
        }
    }
    
}
