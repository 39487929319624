import { capitalize } from 'lodash';

export class Address {
    number?: string;
    street?: string;
    complement?: string;
    zip?: string;
    city?: string;
    country?: string;
    longitude?: string;
    latitude?: string;

    constructor(obj: any) {
        this.number = obj?.number;
        this.street = obj?.street?.toLowerCase();
        this.complement = capitalize(obj?.complement);
        this.zip = obj?.zip;
        this.city = capitalize(obj?.city);
        this.country = obj?.country;
        this.longitude = obj?.longitude;
        this.latitude = obj?.latitude;
    }
}
