import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CypressDirective } from './cypress.directive';

@NgModule({
    declarations: [CypressDirective],
    imports: [CommonModule],
    exports: [CypressDirective],
})
export class CypressModule {}
