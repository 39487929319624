import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FraudShieldBannerComponent } from './fraud-shield-banner.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationModule, IconModule, BadgeModule } from '@fintecture-npm/angular-ui';



@NgModule({
    declarations: [FraudShieldBannerComponent],
    imports: [
        TranslateModule,
        CommonModule,
        NotificationModule,
        IconModule,
        BadgeModule
    ],
    exports: [FraudShieldBannerComponent],
})
export class FraudShieldModule { }
