import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { FeatureFlagsService } from './../services/feature-flags/feature-flags.service';

@Injectable()
export class FeatureFlagsInterceptor implements HttpInterceptor {

    constructor(private featureFlagsService: FeatureFlagsService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    const flags = event.headers.get('x-fte-ff');
                    if (flags) {
                        this.featureFlagsService.updateActiveFlags(flags.split(';'));
                    }
                }
            })
        );
    }
}
