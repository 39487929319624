import { Injectable } from '@angular/core';

const PAYMENT_PAGE_SIZE = 'paymentPageSize';
const DISBURSEMENTS_PAGE_SIZE = 'disbursementPageSize';

@Injectable({
    providedIn: 'root'
})

export class PaginationService {

    private savePagination(key: string, value: string){
        if ([PAYMENT_PAGE_SIZE, DISBURSEMENTS_PAGE_SIZE].includes(key)) {
            localStorage.setItem(key, value);
        }
    }

    private getPagination(key: string){
        if ([PAYMENT_PAGE_SIZE, DISBURSEMENTS_PAGE_SIZE].includes(key) && localStorage.getItem(key)) {
            return localStorage.getItem(key);
        }
        return null;
    }

    private clearPagination(key: string){
        if ([PAYMENT_PAGE_SIZE, DISBURSEMENTS_PAGE_SIZE].includes(key)) {
            localStorage.removeItem(key);
        }
    }

    getPaymentPageSize(){
        return this.getPagination(PAYMENT_PAGE_SIZE);
    }

    setPaymentPagination(size: number){
        this.savePagination(PAYMENT_PAGE_SIZE, size.toString());
    }

    clearPaymentPagination(){
        this.clearPagination(PAYMENT_PAGE_SIZE);
    }

    getDisbursementPageSize(){
        return this.getPagination(DISBURSEMENTS_PAGE_SIZE);
    }

    setDisbursementPagination(size: number){
        this.savePagination(DISBURSEMENTS_PAGE_SIZE, size.toString());
    }

    clearDisbursementPagination(){
        this.clearPagination(DISBURSEMENTS_PAGE_SIZE);
    }
}