
declare let OneTrust: any;
declare let OnetrustActiveGroups: string;

const COOKIES_IDS = {
    'necessary': 'C0001',
    'performance': 'C0002',
    'functional': 'C0003',
    'targeting': 'C0004',
} as const;

const MAX_WAIT = 10 * 1000;

declare type CookieType = keyof typeof COOKIES_IDS;

export function isOneTrustReady(): boolean {
    return typeof OneTrust !== 'undefined';
}

export function whenOneTrustReady(callback: () => void): void {
    if (isOneTrustReady()) {
        callback();
        return;
    }

    const timeoutTimestamp = Date.now() + MAX_WAIT;
    const interval = setInterval(() => {
        if (isOneTrustReady()) {
            callback();
            clearInterval(interval);
        } else if (Date.now() > timeoutTimestamp) {
            clearInterval(interval);
        }
    }, 100);
}

export function areCookiesAccepted(type: CookieType): boolean {
    return OnetrustActiveGroups.includes(COOKIES_IDS[type]);
}

export function deleteCookie(name: string, config = ''): void {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;' + config;
}

export function deleteCookies(names: string[], config = ''): void {
    names.forEach(name => deleteCookie(name, config));
}

export function onCookiesChange(callback: () => void): void {
    let initialConsent = OnetrustActiveGroups;
    OneTrust.OnConsentChanged(() => {
        const nextConsent = OnetrustActiveGroups;
        if (initialConsent !== nextConsent) {
            initialConsent = nextConsent;
            callback();
        }
    });
}

export function openCookiesModal(): void {
    OneTrust.ToggleInfoDisplay();
}
