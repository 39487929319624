import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[cy-id]',
})
export class CypressDirective {
    element: Element;

  @Input('cy-id') set cyId(value: string) {
        this.element.setAttribute('data-cy', value);
    }

  constructor(private elementRef: ElementRef<Element>) {
      this.element = this.elementRef.nativeElement;
  }
}
