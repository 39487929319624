import { StatusType } from '@fintecture-npm/angular-ui';
import { BankAccount } from './bank-account';
import { EnvironmentType } from '../constants/environment-constants';
import { PaymentMethod } from './payment-method';

declare type MembersApplication = {
    id: string;
    role: string;
    fullName: string;
    email: string;
    enabled: boolean;
}
export class Application {
    id: string;
    name: string;
    environment: EnvironmentType;
    logo: string;
    bankAccountId: string;
    bankAccount: BankAccount;
    logo_full: string;
    color: string;
    commercialName: string;
    smsCommercialName: string;
    customizableRtpSms: boolean;
    allowPendingStatus: boolean;
    allowRetryOnFailure: boolean;
    isPendingStatusEditable: boolean; // Allow pending status modification only if app isct policy is equal to immediate or auto_isct
    notifications: {
        notification_type: string,
        trigger_delay: number
    }[];
    isFraudGuardEnabled: boolean;
    allowAutoRefunds: string[];
    paymentMethods: PaymentMethod[];
    withPaymentOtp: boolean;
    members: MembersApplication[];

    constructor(obj: any = {}) {
        this.id = obj.id;
        this.name = obj.name;
        this.logo = obj.logo;
        this.environment = obj.environment;
        this.bankAccountId = obj.bankAccountId;
        this.logo_full = obj.logo_full;
        this.color = obj.color;
        this.commercialName = obj.commercialName;
        this.smsCommercialName = obj.smsCommercialName;
        if (obj.bankAccount) { this.bankAccount = new BankAccount(obj.bankAccount); }
        this.notifications = obj.notifications || [];
        this.customizableRtpSms = obj.customizableRtpSms;
        this.allowPendingStatus = obj.allow_pending_status;
        this.allowRetryOnFailure = obj.allow_retry_on_failure;
        this.isPendingStatusEditable = obj.is_pending_status_editable;
        this.isFraudGuardEnabled = obj.fraud_guard;
        this.allowAutoRefunds = obj.allow_auto_refunds || [];
        if (obj.payment_methods?.length) { 
            this.paymentMethods = obj.payment_methods.map((pm) => new PaymentMethod(pm)); 
        }
        this.withPaymentOtp = obj.with_payment_otp;
        this.members = obj.members || [];
    }

    getLogoCssUrl(): string {
        const url = this.logo || '/assets/img/default-shop-logo.svg';

        return `url(${url})`;
    }

    getLogoFullCssUrl(): string {
        const url = this.logo_full || '/assets/img/icon-shop-logo.svg';

        return `url(${url})`;
    }

    getEnvBadgeType(): StatusType {
        return this.environment?.toLocaleLowerCase() === 'production' ? 'success' : 'warning';
    }
}
