// IMPORTANT!!
// Keep this file aligned with src/style/_screen-sizes.scss

declare type Breakpoints = { [name: string]: Breakpoint }
declare type Breakpoint = { min?: number, max?: number }

export class ScreenSizeConstants {
    static readonly breakpoints: Breakpoints = {
        mobile: { max: 991 },
        desktop: { min: 992 },
        xs: { max: 575 },
        sm: { min: 576, max: 767 },
        md: { min: 768, max: 991 },
        lg: { min: 992, max: 1199 },
        xl: { min: 1200, max: 1399 },
        xxl: { min: 1400 },
    };
}