import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnScreenSizeDirective } from './on-screen-size.directive';



@NgModule({
    declarations: [OnScreenSizeDirective],
    imports: [
        CommonModule
    ],
    exports: [OnScreenSizeDirective]
})
export class OnScreenSizeModule { }
