import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services/language/language.service';

@Pipe({
    name: 'currency'
})
export class LanguageCurrencyPipe extends CurrencyPipe implements PipeTransform {

    private currencyCode = 'EUR';
    private display = 'symbol';

    constructor(private languageService: LanguageService) {
        super(languageService.getCurrentLang());
    }

    transform(value: number | string): any {
        const locale = this.languageService.getCurrentLang();
        const digitsInfo = Number(value) % 1 === 0 ? '1.0-0' : '1.2-2';

        return super.transform(value, this.currencyCode, this.display, digitsInfo, locale);
    }
}
